@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';

$sorter_display: flex !default;
$sorter_flex-align-items: center !default;

$sorter_label-padding: 0 1rem 0 0 !default;
$sorter_label-white-space: nowrap !default;

$sorter_select-max-width: 18rem !default;

.#{$ns}sorter {
    display: $sorter_display;

    @if $sorter_display == flex {
        align-items: $sorter_flex-align-items;
    }

    &__label {
        padding: $sorter_label-padding;
        white-space: $sorter_label-white-space;
    }

    &__select {
        @include media('>=laptop') {
            max-width: $sorter_select-max-width;
        }
    }
}
