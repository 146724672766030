/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-tabs {
  box-shadow: 1px 3px 3rem 0 rgba(72, 72, 72, 0.28); }
  .cs-tabs--store-pickup {
    width: 100%;
    margin: 0;
    box-shadow: none;
    position: relative; }
    @media (max-width: 47.99em) {
      .cs-tabs--store-pickup:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 1px 3px 3rem 0 rgba(72, 72, 72, 0.28);
        z-index: -1;
        content: ''; } }
  .cs-tabs--bar {
    margin: 0 0 0 -1.5rem;
    padding: 0 1.5rem;
    border-bottom: 1px solid #dcdcdc;
    box-shadow: none; }
  .cs-tabs__triggers {
    display: flex;
    overflow: hidden;
    justify-content: space-between; }
    .cs-tabs--bar .cs-tabs__triggers {
      justify-content: flex-start; }
    #store-pickup ~ #shipping .cs-tabs__triggers {
      display: none; }
  .cs-tabs__title {
    flex-basis: 50%;
    background: #f0f0f0;
    box-shadow: inset 0 0 5px 0 #696969;
    margin-top: -1px;
    margin-right: -1px;
    padding-top: 0.2rem;
    padding-left: 0.2rem; }
    .cs-tabs__title--active {
      background: #fff;
      box-shadow: none;
      margin: 0;
      padding: 0; }
      .cs-tabs__title--active .cs-tabs__title-link {
        cursor: default;
        color: #78b0e0 !important; }
        .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link {
          color: #484848;
          box-shadow: none; }
          .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link:after {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;
            content: '';
            z-index: 3; }
          .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link:visited, .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link:hover {
            color: #484848; }
        .cs-tabs__title--active .cs-tabs__title-link:visited, .cs-tabs__title--active .cs-tabs__title-link:hover, .cs-tabs__title--active .cs-tabs__title-link:active {
          color: #78b0e0 !important; }
          .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link:visited, .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link:hover, .cs-tabs--bar .cs-tabs__title--active .cs-tabs__title-link:active {
            color: #484848; }
    .cs-tabs--bar .cs-tabs__title {
      margin: 0;
      padding: 0;
      flex-basis: auto;
      text-align: center;
      border: 1px solid #dcdcdc;
      border-bottom: none;
      border-left-width: 0;
      box-shadow: none;
      transition: background-color 0.15s linear, color 0.15s linear; }
      .cs-tabs--bar .cs-tabs__title:not(.cs-tabs__title--active):hover {
        background-color: #fdfdfd; }
      .cs-tabs--bar .cs-tabs__title:first-child {
        border-left-width: 1px;
        border-top-left-radius: 5px; }
      .cs-tabs--bar .cs-tabs__title:last-child {
        border-top-right-radius: 5px; }
  .cs-tabs__title-link {
    color: #888;
    text-decoration: none;
    line-height: 4rem;
    height: 4rem;
    padding: 0.1rem 1rem;
    display: block;
    position: relative;
    z-index: 2;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer; }
    @media (min-width: 30em) {
      .cs-tabs__title-link {
        padding: 0.1rem 2rem; } }
    @media (min-width: 48em) {
      .cs-tabs__title-link {
        font-size: 1.8rem;
        line-height: 5.5rem;
        height: 5.5rem; } }
    .cs-tabs__title-link:hover, .cs-tabs__title-link:active, .cs-tabs__title-link:focus, .cs-tabs__title-link:visited {
      color: #888; }
    .cs-tabs__title-link:hover {
      text-decoration: none; }
    .cs-tabs--bar .cs-tabs__title-link {
      position: relative;
      height: auto;
      line-height: 120%;
      font-size: 1.2rem;
      padding: 0.6rem 0.5rem;
      font-weight: 300;
      white-space: nowrap; }
      @media (min-width: 30em) {
        .cs-tabs--bar .cs-tabs__title-link {
          padding: 0.8rem 1.5rem; } }
      .cs-tabs--bar .cs-tabs__title-link:hover, .cs-tabs--bar .cs-tabs__title-link:active, .cs-tabs--bar .cs-tabs__title-link:focus, .cs-tabs--bar .cs-tabs__title-link:visited {
        color: #484848; }
  .cs-tabs--bar .cs-tabs__title-span:not(:empty):before {
    content: '('; }
  .cs-tabs--bar .cs-tabs__title-span:not(:empty):after {
    content: ')'; }
  .cs-tabs__content {
    padding: 2rem; }

#store-pickup ~ #shipping #tab-customer {
  display: none; }
