/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
.cs-aftersearch-nav {
  overflow: visible;
  width: calc(100% + 3rem);
  margin: 0 -1.5rem 3rem; }
  @media (min-width: 48em) {
    .cs-aftersearch-nav {
      width: auto;
      margin: 0; } }
  .cs-offcanvas-filters .cs-aftersearch-nav {
    margin: 0;
    width: 100%; }
  .cs-aftersearch-nav__loader {
    display: none; }
  .cs-aftersearch-nav__loader-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    stroke: #dcdcdc;
    width: 8rem;
    height: 8rem; }
  .cs-aftersearch-nav.filter-no-options {
    display: none; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav.filter-no-options {
        display: block; } }
  .cs-aftersearch-nav__filter-options {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-aftersearch-nav__filter {
    position: relative;
    margin: 0; }
    .cs-aftersearch-nav__filter + .cs-aftersearch-nav__filter {
      border-top: 1px solid #dcdcdc; }
      @media (min-width: 48em) {
        .cs-aftersearch-nav__filter + .cs-aftersearch-nav__filter {
          border-top: 0; } }
    .cs-aftersearch-nav__filter--hide-searchbox .search {
      display: none; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav__filter {
        padding-bottom: 0;
        border-bottom: 1px solid #dcdcdc; } }
    .cs-aftersearch-nav__filter--checked:not(.active) > .cs-aftersearch-nav__filter-title:after {
      opacity: 0; }
    .cs-aftersearch-nav__filter--checked > .cs-aftersearch-nav__filter-title:before {
      background: center no-repeat url("../images/icons/aftersearch-nav/check.svg");
      height: 1.3rem;
      width: 1.3rem; }
  .cs-aftersearch-nav__filters-trigger {
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 1em;
    background: #f0f0f0;
    box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.15);
    transition-property: background-color, color, box-shadow;
    transition-duration: 0.3s;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase; }
    .cs-aftersearch-nav__filters-trigger:before, .cs-aftersearch-nav__filters-trigger:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 1rem;
      height: 0.2rem;
      left: auto;
      background-color: currentColor;
      transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-aftersearch-nav__filters-trigger:hover:before, .cs-aftersearch-nav__filters-trigger:hover:after {
      background-color: #888; }
    .cs-aftersearch-nav__filters-trigger[aria-expanded="true"]:before, .cs-aftersearch-nav__filters-trigger[aria-expanded="true"]:after {
      background-color: #fff; }
    .cs-aftersearch-nav__filters-trigger:before, .cs-aftersearch-nav__filters-trigger:after {
      right: 1.5rem;
      transform: translate3d(0, -50%, 0) !important; }
    .cs-aftersearch-nav__filters-trigger:after {
      transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
    .cs-aftersearch-nav__filters-trigger[aria-expanded="true"]:before {
      transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
      opacity: 0; }
    .cs-aftersearch-nav__filters-trigger[aria-expanded="true"]:after {
      transform: translate3d(0, -50%, 0) rotate(0) !important; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav__filters-trigger {
        display: none; } }
    .cs-aftersearch-nav__filters-trigger[aria-expanded='true'] {
      background: #78b0e0;
      color: #fff; }
    .cs-offcanvas-filters .cs-aftersearch-nav__filters-trigger {
      padding-left: 2rem;
      padding-right: 2rem; }
  .cs-aftersearch-nav__filters {
    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 0; }
    .cs-aftersearch-nav__filters[aria-hidden] {
      height: auto; }
    .cs-offcanvas-filters .cs-aftersearch-nav__filters {
      height: auto; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav__filters {
        height: auto;
        border-bottom: none;
        overflow: visible; } }
  .cs-aftersearch-nav__filter-title {
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 700;
    margin: 0;
    overflow: hidden;
    text-decoration: none;
    text-transform: uppercase;
    word-break: break-all;
    z-index: 1;
    width: 100%;
    background: #f0f0f0;
    padding: 1.3rem 3rem 1.3rem 1.5rem; }
    .cs-aftersearch-nav__filter-title:before, .cs-aftersearch-nav__filter-title:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 1rem;
      height: 0.2rem;
      left: auto;
      background-color: currentColor;
      transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-aftersearch-nav__filter-title:before, .cs-aftersearch-nav__filter-title:after {
      right: 1.5rem;
      transform: translate3d(0, -50%, 0) !important; }
    .cs-aftersearch-nav__filter-title:after {
      transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
    .cs-aftersearch-nav__filter-title[aria-expanded="true"]:before {
      transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
      opacity: 0; }
    .cs-aftersearch-nav__filter-title[aria-expanded="true"]:after {
      transform: translate3d(0, -50%, 0) rotate(0) !important; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav__filter-title {
        padding: 2rem 2rem 2rem 0;
        background: transparent; } }
    .cs-offcanvas-filters .cs-aftersearch-nav__filter-title {
      padding-left: 2rem;
      padding-right: 2rem; }
  .cs-aftersearch-nav__filter-content {
    width: 100%;
    margin: 0;
    padding: 2rem 1.5rem;
    display: none; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav__filter-content {
        padding: 0 0 2rem;
        width: auto; } }
    .cs-aftersearch-nav__filter-content[aria-hidden] {
      display: block; }
    .cs-offcanvas-filters .cs-aftersearch-nav__filter-content {
      padding-left: 2rem;
      padding-right: 2rem; }
  .cs-aftersearch-nav .search {
    font-size: 1.3rem; }
  .cs-aftersearch-nav .filter-search {
    font-size: 1em;
    line-height: 1.275em;
    padding: 0.75em 1em;
    color: #484848;
    background: #fff;
    border-radius: 0px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    background-clip: padding-box;
    font-family: inherit;
    margin-bottom: 1rem; }
    .cs-aftersearch-nav .filter-search:hover:not([disabled]), .cs-aftersearch-nav .filter-search:focus:not([disabled]) {
      border: 1px solid #78b0e0;
      box-shadow: none; }
    .cs-aftersearch-nav .filter-search::placeholder {
      opacity: 1;
      color: #888; }
    .cs-aftersearch-nav .filter-search[disabled] {
      opacity: 0.65; }
  .cs-aftersearch-nav .items {
    margin: 0;
    padding: 0;
    list-style: none;
    columns: 2; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav .items {
        padding-top: 0;
        columns: 1; } }
  .cs-aftersearch-nav .item {
    margin: 1rem 0 0;
    font-size: 1.3rem;
    page-break-inside: avoid;
    /* Firefox */
    break-inside: avoid;
    /* IE 10+ */ }
    .cs-aftersearch-nav .item:first-child {
      margin-top: 0; }
    .cs-aftersearch-nav .item a {
      font-size: 1.4rem;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: center;
      border: 0;
      padding: 0;
      margin: 0;
      width: 100%;
      align-items: center;
      font-size: 1em;
      flex-wrap: nowrap;
      align-content: center; }
      .cs-aftersearch-nav .item a:hover {
        text-decoration: none; }
    .cs-aftersearch-nav .item input[type='checkbox'] {
      display: inline-block;
      height: 1em;
      width: 1em;
      margin: -0.0625em calc(0.5em + 1px) 0 0;
      font-size: 1em;
      overflow: hidden;
      vertical-align: middle;
      appearance: none;
      flex: 0 0 auto;
      cursor: pointer;
      border: 1px solid #dcdcdc;
      box-shadow: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 1em 1em;
      outline: 0;
      border-radius: 50%;
      transition: background-color 0.5s, border-color 0.5s;
      border-radius: 0px;
      background-size: 1em 1em;
      font-size: 1em; }
      .cs-aftersearch-nav .item input[type='checkbox']::-moz-focus-inner, .cs-aftersearch-nav .item input[type='checkbox']:-moz-focusring {
        color: transparent !important;
        text-shadow: 0 0 0 #000 !important; }
      .cs-aftersearch-nav .item input[type='checkbox']:hover:not([disabled]), .cs-aftersearch-nav .item input[type='checkbox']:focus:not([disabled]) {
        border: 1px solid #346097;
        box-shadow: none;
        background-color: transparent; }
      .cs-aftersearch-nav .item input[type='checkbox']:checked {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%2378b0e0' r='4' cy='7' cx='7'/%3e%3c/svg%3e"); }
        .cs-aftersearch-nav .item input[type='checkbox']:checked, .cs-aftersearch-nav .item input[type='checkbox']:checked:hover, .cs-aftersearch-nav .item input[type='checkbox']:checked:focus {
          border: 1px solid #78b0e0;
          background-color: transparent;
          box-shadow: none; }
      .cs-aftersearch-nav .item input[type='checkbox'][disabled],
      .cs-aftersearch-nav .item input[type='checkbox'][disabled] + .label,
      .cs-aftersearch-nav .item input[type='checkbox'][disabled] + .cs-input__label {
        opacity: 0.65; }
      .cs-aftersearch-nav .item input[type='checkbox']:checked {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%2378b0e0' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e"); }
    .cs-aftersearch-nav .item label {
      display: inline;
      align-items: center; }
      .cs-aftersearch-nav .item label:hover {
        cursor: pointer; }
  .cs-aftersearch-nav .count {
    display: inline-flex;
    color: #888;
    margin-left: 0.2em; }
    .cs-aftersearch-nav .count:before {
      content: '('; }
    .cs-aftersearch-nav .count:after {
      content: ')'; }
  .cs-aftersearch-nav .filter-count-label {
    display: none; }
  .cs-aftersearch-nav .no-results-message {
    font-size: 1.3rem; }
  .cs-aftersearch-nav .actions {
    margin-top: 1rem; }
  .cs-aftersearch-nav .show-more,
  .cs-aftersearch-nav .show-less {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    font-size: 1.3rem; }
    .cs-aftersearch-nav .show-more::-moz-focus-inner,
    .cs-aftersearch-nav .show-less::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-aftersearch-nav .show-more, .cs-aftersearch-nav .show-more:visited,
    .cs-aftersearch-nav .show-less,
    .cs-aftersearch-nav .show-less:visited {
      cursor: pointer;
      border-radius: 0px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #78b0e0;
      color: #78b0e0;
      background-color: #fff; }
      .cs-aftersearch-nav .show-more *, .cs-aftersearch-nav .show-more:visited *,
      .cs-aftersearch-nav .show-less *,
      .cs-aftersearch-nav .show-less:visited * {
        color: #78b0e0;
        fill: #78b0e0; }
    .cs-aftersearch-nav .show-more:hover:not([disabled]), .cs-aftersearch-nav .show-more:focus:not([disabled]), .cs-aftersearch-nav .show-more:active:not([disabled]),
    .cs-aftersearch-nav .show-less:hover:not([disabled]),
    .cs-aftersearch-nav .show-less:focus:not([disabled]),
    .cs-aftersearch-nav .show-less:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #346097;
      color: #fff;
      background-color: #346097; }
      .cs-aftersearch-nav .show-more:hover:not([disabled]) *, .cs-aftersearch-nav .show-more:focus:not([disabled]) *, .cs-aftersearch-nav .show-more:active:not([disabled]) *,
      .cs-aftersearch-nav .show-less:hover:not([disabled]) *,
      .cs-aftersearch-nav .show-less:focus:not([disabled]) *,
      .cs-aftersearch-nav .show-less:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-aftersearch-nav .show-more[disabled],
    .cs-aftersearch-nav .show-less[disabled] {
      opacity: 0.65;
      cursor: auto; }
  .cs-aftersearch-nav__toggle-wrapper {
    display: none; }
  .cs-aftersearch-nav--horizontal {
    margin-top: 1rem; }
    @media (min-width: 48em) {
      .cs-aftersearch-nav--horizontal {
        z-index: 20;
        padding-bottom: 0;
        margin-bottom: 2rem;
        margin-top: 1rem; }
        .cs-aftersearch-nav--horizontal.cs-aftersearch-nav--expanded .cs-aftersearch-nav__filter {
          display: block !important; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filters {
          width: 100%;
          border-top: 1px solid #dcdcdc;
          border-bottom: 1px solid #dcdcdc; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__state {
          width: 100%;
          order: 2; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-options {
          margin-top: 0.8em;
          margin-right: -0.8em;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper {
          margin-right: auto;
          margin-bottom: 0.8em; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter {
          border-bottom: none;
          margin: 0 0.8em 0.8em 0; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-title {
          font-size: 1.4rem;
          overflow: visible;
          background-color: #f0f0f0;
          line-height: 1.275em;
          padding: 0.75em 1em;
          border: 1px solid #f0f0f0;
          box-shadow: none;
          transition: box-shadow 0.3s, background 0.3s, border 0.3s;
          word-break: unset; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-title:hover {
            background-color: #dcdcdc;
            border: 1px solid #dcdcdc; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-title[aria-expanded='true'] {
            background-color: #fff;
            border: 1px solid #fff;
            box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.15); }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-title-text {
          font-size: 1.1rem; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button {
          display: inline-block;
          font-size: 1.4rem;
          font-weight: 700;
          text-align: center;
          position: relative;
          line-height: 1.275em;
          margin: 0;
          text-transform: none;
          padding: 0.75em 1em;
          box-sizing: border-box;
          transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
          vertical-align: middle;
          z-index: 5;
          position: relative;
          width: 100%;
          border-radius: 0 !important;
          position: relative;
          display: flex; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button::-moz-focus-inner {
            border: 0;
            padding: 0;
            margin: 0; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button, .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:visited {
            cursor: pointer;
            border-radius: 0px;
            outline-offset: 1px;
            text-decoration: none;
            box-shadow: none;
            border: 1px solid transparent;
            color: #fff;
            background-color: #78b0e0; }
            .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button *, .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:visited * {
              color: #fff;
              fill: #fff; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:hover:not([disabled]), .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:focus:not([disabled]), .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:active:not([disabled]) {
            box-shadow: none;
            outline: none;
            text-decoration: none;
            border: 1px solid transparent;
            color: #fff;
            background-color: #346097; }
            .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:hover:not([disabled]) *, .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:focus:not([disabled]) *, .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:active:not([disabled]) * {
              color: #fff;
              fill: #fff; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button[disabled] {
            opacity: 0.65;
            cursor: auto; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:before, .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 1rem;
            height: 0.2rem;
            left: auto;
            background-color: currentColor;
            transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
            backface-visibility: hidden; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:before, .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:after {
            right: 1.5rem;
            transform: translate3d(0, -50%, 0) !important; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button:after {
            transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button[aria-expanded="true"]:before {
            transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
            opacity: 0; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button[aria-expanded="true"]:after {
            transform: translate3d(0, -50%, 0) rotate(0) !important; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button[aria-expanded='true'] .cs-aftersearch-nav__toggle-text--less {
            display: block; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-button[aria-expanded='true'] .cs-aftersearch-nav__toggle-text--more {
            display: none; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-text {
          font-size: 1.1rem;
          text-transform: uppercase; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-text--less {
            display: none; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-text--more {
            display: block; }
        .cs-aftersearch-nav--horizontal .filter-attribute-kolor .cs-aftersearch-nav__filter-content {
          display: block !important;
          position: relative;
          opacity: 1;
          box-shadow: none; }
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-content {
          position: absolute;
          min-width: 100%;
          background-color: #fff;
          transition: opacity 0.3s ease-in-out;
          overflow: visible;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          overflow-y: auto;
          opacity: 0;
          box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15); } }
    @media (min-width: 48em) and (min-width: 48em) {
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-content {
        padding-bottom: 0; } }
    @media (min-width: 48em) {
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-content[aria-hidden='false'] {
            z-index: 6;
            opacity: 1; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-content > div,
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-content > ol {
            padding: 1rem 1.5rem; }
          .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter-content--align-right {
            left: auto;
            right: 0; }
        .cs-aftersearch-nav--horizontal .item:last-child,
        .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__swatch {
          margin-bottom: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter,
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper {
    flex-basis: calc( 25% - 0.8em); }
  .cs-aftersearch-nav--horizontal .filter-attribute-kolor {
    flex-basis: 100%; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(-n + 3) {
    display: block; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(n + 4) {
    display: none; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(4):nth-last-child(2) {
    display: block; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper:nth-child(n + 6) {
    display: block; } }

@media (min-width: 64em) and (max-width: 89.99em) {
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter,
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper {
    flex-basis: calc( 20% - 0.8em); }
  .cs-aftersearch-nav--horizontal .filter-attribute-kolor {
    flex-basis: 100%; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(-n + 4) {
    display: block; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(n + 5) {
    display: none; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(5):nth-last-child(2) {
    display: block; }
  .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper:nth-child(n + 7) {
    display: block; } }
    @media (min-width: 90em) {
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter,
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper {
        flex-basis: calc( 16.66667% - 0.8em); }
      .cs-aftersearch-nav--horizontal .filter-attribute-kolor {
        flex-basis: 100%; }
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(-n + 5) {
        display: block; }
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(n + 6) {
        display: none; }
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__filter:nth-child(6):nth-last-child(2) {
        display: block; }
      .cs-aftersearch-nav--horizontal .cs-aftersearch-nav__toggle-wrapper:nth-child(n + 8) {
        display: block; } }
