/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
.cs-select {
  display: block;
  font-size: 1em;
  line-height: 1.275em;
  padding: 0.75em 1em;
  padding-right: 2.3em;
  color: #484848;
  border-radius: 0px;
  border: 1px solid #dcdcdc;
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  margin: 0 0 0.2em;
  outline: 0;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.8em top 52%, 0 0;
  background-size: 0.7em auto, 100%;
  transition: border-color 0.5s; }
  .cs-select:-moz-focusring, .cs-select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important; }
  .cs-select:hover:not([disabled]), .cs-select:focus:not([disabled]) {
    border: 1px solid #78b0e0;
    box-shadow: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%2378b0e0'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%); }
  .cs-select[disabled] {
    opacity: 0.65; }
  .cs-select option {
    font-weight: 500;
    padding: 0;
    color: initial; }
