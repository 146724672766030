/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.cs-aftersearch-nav-range {
  font-size: 1.3rem; }
  .cs-aftersearch-nav-range--inputs-only .cs-aftersearch-nav-range__slider {
    display: none; }
  .cs-aftersearch-nav-range--slider-only .cs-aftersearch-nav-range__fields {
    display: none; }
  .cs-aftersearch-nav-range--slider-only .cs-aftersearch-nav-range__slider-label {
    display: inline-block; }
  .cs-aftersearch-nav-range__fields {
    display: flex;
    align-items: center;
    margin-bottom: 1.9rem; }
  .cs-aftersearch-nav-range__label {
    color: #696969; }
    .cs-aftersearch-nav-range__label--from {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
      /* 1 */ }
    .cs-aftersearch-nav-range__label--to {
      margin: 0 0.5em;
      flex: 1 0 auto;
      text-align: center; }
  .cs-aftersearch-nav-range__field {
    font-size: 1.4rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
    font-size: 1em;
    width: auto;
    min-width: 10rem;
    max-width: 15rem;
    flex: 1 1 auto; }
  .cs-aftersearch-nav-range__currency {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    color: #484848; }
  .cs-aftersearch-nav-range__input {
    font-size: 1em;
    line-height: 1.275em;
    padding: 0.75em 1em;
    color: #484848;
    background: #fff;
    border-radius: 0px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    background-clip: padding-box;
    font-family: inherit;
    margin-bottom: 0; }
    .cs-aftersearch-nav-range__input:hover:not([disabled]), .cs-aftersearch-nav-range__input:focus:not([disabled]) {
      border: 1px solid #78b0e0;
      box-shadow: none; }
    .cs-aftersearch-nav-range__input::placeholder {
      opacity: 1;
      color: #888; }
    .cs-aftersearch-nav-range__input[disabled] {
      opacity: 0.65; }
  .cs-aftersearch-nav-range__slider {
    margin-bottom: 1.9rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .cs-aftersearch-nav-range__slider-label {
    display: none;
    margin-bottom: 0.5em; }
  .cs-aftersearch-nav-range__slider-bar {
    width: 100%; }
  .cs-aftersearch-nav-range__actions-box {
    display: flex;
    align-items: center; }
  .cs-aftersearch-nav-range__message {
    flex: 1;
    margin-right: 1em;
    color: #484848; }
  .cs-aftersearch-nav-range__apply {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    font-size: 1em; }
    .cs-aftersearch-nav-range__apply::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-aftersearch-nav-range__apply, .cs-aftersearch-nav-range__apply:visited {
      cursor: pointer;
      border-radius: 0px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #78b0e0;
      color: #78b0e0;
      background-color: #fff; }
      .cs-aftersearch-nav-range__apply *, .cs-aftersearch-nav-range__apply:visited * {
        color: #78b0e0;
        fill: #78b0e0; }
    .cs-aftersearch-nav-range__apply:hover:not([disabled]), .cs-aftersearch-nav-range__apply:focus:not([disabled]), .cs-aftersearch-nav-range__apply:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #346097;
      color: #fff;
      background-color: #346097; }
      .cs-aftersearch-nav-range__apply:hover:not([disabled]) *, .cs-aftersearch-nav-range__apply:focus:not([disabled]) *, .cs-aftersearch-nav-range__apply:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-aftersearch-nav-range__apply[disabled] {
      opacity: 0.65;
      cursor: auto; }
