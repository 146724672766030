/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-offcanvas-filters {
  position: relative;
  z-index: 1001; }
  .cs-offcanvas-filters__drawer, .cs-offcanvas-filters__overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0; }
  .cs-offcanvas-filters__overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.3s, transform 1ms 0.3s;
    transform: scale(0);
    cursor: pointer; }
  .cs-offcanvas-filters .overlay--visible {
    transform: scale(1);
    background: rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s; }
  .cs-offcanvas-filters__drawer {
    position: fixed;
    background: #fff;
    transition: transform 0.3s;
    width: 90%;
    max-width: 40rem;
    left: 0;
    right: auto;
    transform: translate3d(-105%, 0, 0);
    overflow-y: auto; }
  .cs-offcanvas-filters .drawer--visible {
    transform: translate3d(0, 0, 0) !important; }
  .cs-offcanvas-filters__headline {
    padding: 2rem;
    font-size: 2.6rem; }
  .cs-offcanvas-filters__close {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer; }
  .cs-offcanvas-filters__close-icon {
    position: relative;
    transform: rotate(45deg) translateY(-0.5rem);
    width: 100%;
    height: 100%;
    display: inline-block; }
    .cs-offcanvas-filters__close-icon:before, .cs-offcanvas-filters__close-icon:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 2rem;
      height: 0.2rem;
      left: auto;
      background-color: #484848;
      transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-offcanvas-filters__close-icon:hover:before, .cs-offcanvas-filters__close-icon:hover:after {
      background-color: #696969; }
    .cs-offcanvas-filters__close-icon[aria-expanded="true"]:before, .cs-offcanvas-filters__close-icon[aria-expanded="true"]:after {
      background-color: #888; }
    .cs-offcanvas-filters__close-icon:before, .cs-offcanvas-filters__close-icon:after {
      right: 1rem;
      transform: translate3d(0, -50%, 0) !important; }
    .cs-offcanvas-filters__close-icon:after {
      transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
    .cs-offcanvas-filters__close-icon[aria-expanded="true"]:before {
      transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
      opacity: 0; }
    .cs-offcanvas-filters__close-icon[aria-expanded="true"]:after {
      transform: translate3d(0, -50%, 0) rotate(0) !important; }
