/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-page-category {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media (min-width: 48em) {
    .cs-page-category {
      padding-top: 3rem;
      padding-bottom: 3rem; } }
  @media (min-width: 48em) {
    .page-layout-2columns-left .cs-page-category__columns {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch; }
      .page-layout-2columns-left .cs-page-category__columns:before, .page-layout-2columns-left .cs-page-category__columns:after {
        display: none; } }
  .page-layout-2columns-left .cs-page-category__messages {
    width: 100%; }
  .page-layout-2columns-left .cs-page-category__sidebar {
    order: 1; }
    @media (min-width: 48em) {
      .page-layout-2columns-left .cs-page-category__sidebar {
        width: calc(100% / 3);
        border-right: 2px solid #f0f0f0;
        padding: 0 1.5rem 0 0; } }
    @media (min-width: 64em) {
      .page-layout-2columns-left .cs-page-category__sidebar {
        width: calc(100% / 4);
        border-right: 2px solid #f0f0f0;
        padding: 0 1.5rem 0 0; } }
    @media (min-width: 80em) {
      .page-layout-2columns-left .cs-page-category__sidebar {
        width: calc(100% / 4);
        border-right: 2px solid #f0f0f0; } }
    @media (min-width: 90em) {
      .page-layout-2columns-left .cs-page-category__sidebar {
        width: calc(100% / 4);
        border-right: 2px solid #f0f0f0; } }
    @media (min-width: 120em) {
      .page-layout-2columns-left .cs-page-category__sidebar {
        width: calc(100% / 4);
        border-right: 2px solid #f0f0f0; } }
  .page-layout-2columns-left .cs-page-category__main {
    order: 2; }
    @media (min-width: 48em) {
      .page-layout-2columns-left .cs-page-category__main {
        width: calc(100% - (100% / 3));
        padding: 1.5rem 0 0 1.5rem; } }
    @media (min-width: 64em) {
      .page-layout-2columns-left .cs-page-category__main {
        width: calc(100% - (100% / 4));
        padding: 1.5rem 0 0 1.5rem; } }
    @media (min-width: 80em) {
      .page-layout-2columns-left .cs-page-category__main {
        width: calc(100% - (100% / 4)); } }
    @media (min-width: 90em) {
      .page-layout-2columns-left .cs-page-category__main {
        width: calc(100% - (100% / 4)); } }
    @media (min-width: 120em) {
      .page-layout-2columns-left .cs-page-category__main {
        width: calc(100% - (100% / 4)); } }
  .page-layout-2columns-left .cs-page-category .cs-page-category__main--search-no-result {
    width: 100%; }
  .page-layout-1column .cs-page-category__main {
    display: flex;
    flex-direction: column; }
    .page-layout-1column .cs-page-category__main .page-title-wrapper {
      order: -2; }
    .page-layout-1column .cs-page-category__main .cs-aftersearch-nav--horizontal {
      order: -1; }
  @media (max-width: 47.99em) {
    .cs-page-category__columns {
      display: flex;
      flex-direction: column; } }
  .cs-page-category__toolbar-wrapper {
    padding: 0; }
    @media (min-width: 48em) {
      .ie11 .cs-page-category__toolbar-wrapper .cs-toolbar__item--pagination {
        margin-left: 0;
        flex-grow: 1;
        justify-content: flex-end; } }
    @media (min-width: 48em) {
      .ie11 .cs-page-category__toolbar-wrapper .cs-toolbar__item--pagination .cs-pagination__content {
        justify-content: flex-end; } }
    .cs-page-category__toolbar-wrapper--top {
      border-bottom: 1px solid #f0f0f0; }
      .cs-page-category__toolbar-wrapper--top .cs-toolbar__item--limiter, .cs-page-category__toolbar-wrapper--top .cs-toolbar__item--pagination {
        display: none; }
      @media (min-width: 48em) {
        .cs-page-category__toolbar-wrapper--top .cs-toolbar__item--pagination {
          display: block; } }
      @media (min-width: 64em) {
        .cs-page-category__toolbar-wrapper--top .cs-toolbar__item--limiter {
          display: block; } }
    .cs-page-category__toolbar-wrapper--bottom {
      margin-top: 1px;
      border-top: 1px solid #f0f0f0; }
      .cs-page-category__toolbar-wrapper--bottom .cs-toolbar {
        justify-content: center; }
        @media (max-width: 47.99em) {
          .cs-page-category__toolbar-wrapper--bottom .cs-toolbar__item--sorter,
          .cs-page-category__toolbar-wrapper--bottom .cs-toolbar__item--viewmode-switcher .cs-page-category__toolbar-wrapper--bottom .cs-toolbar__item--amount {
            display: none; } }
        @media (max-width: 63.99em) {
          .cs-page-category__toolbar-wrapper--bottom .cs-toolbar__item--limiter {
            display: none; } }
        .cs-page-category__toolbar-wrapper--bottom .cs-toolbar__item--pagination {
          display: flex;
          justify-content: center; }
  .cs-page-category__products-wrapper {
    margin: 0; }

.category-image {
  margin: 2rem 0; }
