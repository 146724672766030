/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-search-results-cms {
  margin: 0;
  padding: 0;
  list-style: none; }
  .cs-search-results-cms__item {
    margin-bottom: 0 !important; }
    .cs-search-results-cms__item + .cs-search-results-cms__item {
      margin-top: 1.5rem; }
  .cs-search-results-cms__link {
    display: inline-flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.2; }
  .cs-search-results-cms__icon {
    fill: #888;
    width: 2em;
    height: 2em;
    flex-shrink: 0; }
  .cs-search-results-cms__title {
    color: #78b0e0;
    margin-left: 1.5rem; }

.catalogsearch-result-index dt.title {
  font-family: "Lato", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.8rem;
  line-height: 1.1;
  color: #484848;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }
