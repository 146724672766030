@import 'config/variables';
@import 'vendors/include-media';
@import 'pages/layout/2columns-left/mixin';
$page-category_border-base: 1px solid $color_border-200 !default;
$page-category_top-padding-mobile: 1.5rem !default;
$page-category_top-padding-tablet-up: 2 * $page-category_top-padding-mobile !default;
$page-category_bottom-padding-mobile: 1.5rem !default;
$page-category_bottom-padding-tablet-up: 2 * $page-category_bottom-padding-mobile !default;
$page-category_toolbar-wrapper-margin: 1.5rem 0 0 !default;
$page-category_toolbar-wrapper--top-border-bottom: $page-category_border-base !default;
$page-category_toolbar-wrapper--bottom-border-top: $page-category_border-base !default;
$page-category_products-wrapper-margin: 0 !default;

.#{$ns}page-category {
  padding-top: $page-category_top-padding-mobile;
  padding-bottom: $page-category_bottom-padding-mobile;
  @include media('>=tablet') {
    padding-top: $page-category_top-padding-tablet-up;
    padding-bottom: $page-category_bottom-padding-tablet-up;
  }

  .page-layout-2columns-left & {
    @include two-columns-left();
  }

  &__main {
    .page-layout-1column & {
      display: flex;
      flex-direction: column;

      .page-title-wrapper {
        order: -2;
      }

      .#{$ns}aftersearch-nav--horizontal {
        order: -1;
      }
    }
  }

  &__columns {
    @include media('<tablet') {
      display: flex;
      flex-direction: column;
    }
  }

  &__toolbar-wrapper {
    //padding: $page-category_toolbar-wrapper-margin;
    padding: 0;
    // Fix IE not supporting auto margin for pagination item
    .#{$ns}toolbar__item {
      &--pagination {
        .ie11 & {
          @include media('>=tablet') {
            margin-left: 0;
            flex-grow: 1;
            justify-content: flex-end;
          }

          .#{$ns}pagination__content {
            @include media('>=tablet') {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    &--top {
      border-bottom: $page-category_toolbar-wrapper--top-border-bottom;

      .#{$ns}toolbar__item {
        &--limiter,
        &--pagination {
          display: none;
        }
      }
      @include media('>=tablet') {
        .#{$ns}toolbar__item {
          &--pagination {
            display: block;
          }
        }
      }
      @include media('>=laptop') {
        .#{$ns}toolbar__item {
          &--limiter {
            display: block;
          }
        }
      }
    }

    &--bottom {
      margin-top: 1px;
      border-top: $page-category_toolbar-wrapper--bottom-border-top;

      .#{$ns}toolbar {
        justify-content: center;

        &__item {
          @include media('<tablet') {
            &--sorter,
            &--viewmode-switcher &--amount {
              display: none;
            }
          }
          @include media('<laptop') {
            &--limiter {
              display: none;
            }
          }

          &--pagination {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  &__products-wrapper {
    margin: $page-category_products-wrapper-margin;
  }
}

.category-image {
  margin: 2rem 0;
}