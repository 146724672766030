@import 'config/variables';
@import 'vendors/include-media';
@import 'components/pseudoicon/mixin';

$offcanvas-filters_transition-time: $transition-default-time !default;
$offcanvas-filters_overlay-background: $modals_overlay-background !default;
$offcanvas-filters_drawer-background: $color_background-200 !default;
$offcanvas-filters_spinner-color: $color_primary-500 !default;

$offcanvas-filters__close-include-pseudoicon: true !default;
$offcanvas-filters__close-pseudoicon-type: 'plus' !default;
$offcanvas-filters__close-width: 2rem !default;
$offcanvas-filters__close-line-width: 0.2rem !default;
$offcanvas-filters__close-color: $color_text-600 !default;
$offcanvas-filters__close-color-hover: $color_text-500 !default;

.#{$ns}offcanvas-filters {
    $root: &;

    position: relative;
    z-index: 1001;

    &__drawer,
    &__overlay {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }

    &__overlay {
        width: 100%;
        height: 100%;
        background-color: transparent;
        // Setting 1ms because 0 doesn't seem to work and we need seamless transition for scale here.
        transition: background-color $offcanvas-filters_transition-time,
            transform 1ms $offcanvas-filters_transition-time;
        transform: scale(0);
        cursor: pointer;
    }

    .overlay {
        &--visible {
            transform: scale(1);
            background: $offcanvas-filters_overlay-background;
            transition: background-color $offcanvas-filters_transition-time;
        }
    }

    &__drawer {
        position: fixed;
        background: $offcanvas-filters_drawer-background;
        transition: transform $offcanvas-filters_transition-time;
        width: 90%;
        max-width: 40rem;
        left: 0;
        right: auto;
        transform: translate3d(-105%, 0, 0);
        overflow-y: auto;
    }

    .drawer {
        &--visible {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    &__headline {
        padding: 2rem;
        font-size: $font-size_headline-2;
    }

    &__close {
        width: 4rem;
        height: 4rem;
        position: absolute;
        right: 1.5rem;
        top: 1rem;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    &__close-icon {
        @if ($offcanvas-filters__close-include-pseudoicon) {
            @include pseudoicon(
                $type: $offcanvas-filters__close-pseudoicon-type,
                $color: $offcanvas-filters__close-color,
                $color-hover: $offcanvas-filters__close-color-hover,
                $width: $offcanvas-filters__close-width,
                $height: $offcanvas-filters__close-line-width,
                $position-side-offset: 1rem
            );
        }

        transform: rotate(45deg) translateY(-0.5rem);
        width: 100%;
        height: 100%;
        display: inline-block;
    }
}
